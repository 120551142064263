import * as React from "react";
import Layout from "../../components/Layout";

export default function Calendar() {
  return (
    <Layout>
      Calendar
    </Layout>
  )
}
